<template>
    <div class="pb-2">

        <div class="p-2">
            <!-- header -->
            <div class="w-100 d-flex">
                <img src="https://w7.pngwing.com/pngs/129/292/png-transparent-female-avatar-girl-face-woman-user-flat-classy-users-icon.png"
                            class="rounded-circle m-auto" style="height:80px;width:80px" />
            </div>
            <div class="text-white text-center mt-1">
                <div style="font-size: 18px;" class="font-weight-bolder">House of dogs somewhere</div>
                <p class="mt-1">
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to
                </p>
            </div>
            <!-- end header -->
        </div>

        <!-- gallery -->
        <div class="mt-3 px-2">
            <h2 class="h2 text-white font-weight-bolder mb-0 pr-1" style="padding-bottom:.5em;border-bottom: 1px solid #FFAD32;width: fit-content;">Training Gallery</h2>
        </div>
        <swiper :options="swiperOptions" class="w-100 mt-2">
            <swiper-slide v-for="(data, index) in swiperData" :key="index" class="rounded swiper-shadow" style="width: fit-content;">
                <img @click="changeImage(data,index)" style="height:120px;width:120px;object-fit: cover;border-radius: 10px;" class="" :src="data.url" />
            </swiper-slide> 
        </swiper>

        <image-viewer-vue 
            v-if="imageViewerFlag" 
            @closeImageViewer="imageViewerFlag = false" 
            :imgUrlList="imgUrlList"
            :index="currentIndex"
            :title="title"
            :closable=true
            :cyclical=false>
        </image-viewer-vue>
        <!-- end gallery -->

        <!-- trainer -->
        <div class="mt-3 px-2">
            <h2 class="h2 text-white font-weight-bolder mb-0 pr-1" style="padding-bottom:.5em;border-bottom: 1px solid #FFAD32;width: fit-content;">Trainer</h2>
        </div>
        <div v-for="item in [1,2,3]">
            <router-link to="/training/individual" class="d-flex align-items-center px-2 pt-1" style="gap:10px 20px">
                <div>
                    <img src="https://w7.pngwing.com/pngs/129/292/png-transparent-female-avatar-girl-face-woman-user-flat-classy-users-icon.png"
                        class="rounded-circle" style="height:80px;width:80px" />
                </div>
                <div class="flex-fill">
                    <h3 class="text-white h4 mb-0">Jane Smith Doe</h3>
                    <h3 class="text-white h6 mb-0 d-flex align-items-center" style="margin: .5em 0 !important;font-weight: normal;gap:5px">
                        Rating : 4.5 <i class="bx bxs-star" style="color: #FFAD32;"></i>
                    </h3>
                    <h3 class="text-white h6 mb-0 d-flex align-items-center" style="margin: .5em 0 !important;font-weight: normal;gap:5px">
                        Availability : <strong class="text-success">Available</strong>
                    </h3>
                </div>
                <div>
                    <h3 class="text-white h6 mb-0 text-right" style=""><i class="bx bx-chevron-right" style="font-size: 22px;" ></i></h3>
                </div>
            </router-link>
            <div class="d-flex my-1">
                <div class="w-100 m-auto" style="background-color: #FFAD32;height: 2px;max-width: 150px;">

                </div>
            </div>
        </div>
        <!-- end trainer -->

    </div>
</template>

<script>

import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
    components: {
        Swiper, SwiperSlide
    },
    methods: {
        changeImage(data,index){
            this.imageViewerFlag = true
            this.imgUrlList = [data.url]
        },
    },
    data(){
        return {
            swiperOptions: {
                slidesPerView: 'auto',
                spaceBetween: 10, 
                centeredSlides: true,
                centeredSlidesBounds: true

            },
            swiperData: [
                { url : 'https://upload.wikimedia.org/wikipedia/commons/thumb/4/4d/Cat_November_2010-1a.jpg/1200px-Cat_November_2010-1a.jpg'},
                { url : 'https://img.freepik.com/free-photo/red-white-cat-i-white-studio_155003-13189.jpg?w=2000'},
                { url : 'https://upload.wikimedia.org/wikipedia/commons/thumb/4/4d/Cat_November_2010-1a.jpg/1200px-Cat_November_2010-1a.jpg'},
                { url : 'https://img.freepik.com/free-photo/red-white-cat-i-white-studio_155003-13189.jpg?w=2000'},
                { url : 'https://upload.wikimedia.org/wikipedia/commons/thumb/4/4d/Cat_November_2010-1a.jpg/1200px-Cat_November_2010-1a.jpg'},
                { url : 'https://img.freepik.com/free-photo/red-white-cat-i-white-studio_155003-13189.jpg?w=2000'},
            ],
            imageViewerFlag: false,
            currentIndex: 0,
            title: 'Image Preview',
            imgUrlList: []
        }
    }
}

</script>